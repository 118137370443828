import React from "react"
import PropTypes from 'prop-types'
import { updateAppointment } from 'pages/agenda/actions'

const ConfirmUpdate = ({ formik, returnToForm, closeModal }) => {
  const onUpdateAppointment = () => {
    updateAppointment(formik.values).then(() => closeModal())
                           .catch(({ message }) => formik.setFieldValue('errorMessage', message))
  }

  const sendable = moment().isBefore(formik.values.endAt)
  const sendText = '(une notification sera envoyée aux participants)'
  const unsendText = '(aucune notification ne sera envoyée aux participants)'
  const sendableText = sendable ? sendText : unsendText
  const sendableClass = sendable ? 'text-success' : 'text-danger'

  return (
    <>
      <div className="modal-body">
        <h4>Êtes-vous sûr de vouloir modifier ce rendez-vous&nbsp;?</h4>

        <h5 className={sendableClass}>{sendableText}</h5>
      </div>

      <div className="modal-footer">
        <button className="btn btn-secondary mr-1" type="button" onClick={returnToForm}>
          Non
        </button>

        <button className="btn btn-primary" type="button" onClick={onUpdateAppointment}>
          Oui, j'en suis sûr
        </button>
      </div>
    </>
  )
}

ConfirmUpdate.propTypes = {
  formik: PropTypes.object.isRequired,
  returnToForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default ConfirmUpdate
