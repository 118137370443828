import React from "react"
import PropTypes from 'prop-types'
import { deleteAppointment } from 'pages/agenda/actions'

const ConfirmDelete = ({ id, returnToForm, closeModal }) => {
  const onDeleteAppointment = () => deleteAppointment(id).then(() => closeModal())

  return (
    <>
      <div className="modal-body">
        <h4>Êtes-vous sûr de vouloir supprimer ce rendez-vous&nbsp;?</h4>
      </div>

      <div className="modal-footer">
        <button className="btn btn-secondary mr-1" type="button" onClick={returnToForm}>
          Non
        </button>

        <button className="btn btn-primary" type="button" onClick={onDeleteAppointment}>
          Oui, j'en suis sûr
        </button>
      </div>
    </>
  )
}

ConfirmDelete.propTypes = {
  id: PropTypes.number.isRequired,
  returnToForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default ConfirmDelete
