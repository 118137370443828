import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, ErrorMessage } from 'components'
import { initAjaxSelectpicker } from './utils'

const AjaxSelect = ({
  name,
  field,
  formik,
  error,
  ajax,
  icon,
  multiple,
  options,
  callback,
  disabled
}) => {
  const selectRef = useRef()
  const inputClasses = classNames('form-control', { 'is-invalid': !!error })

  useEffect(() => initAjaxSelectpicker(selectRef.current, ajax), [])

  useEffect(() => { $(selectRef.current).selectpicker('refresh') })

  const onChange = ({ target }) => {
    const value = Array.from(target.selectedOptions).map(({ value }) => value)
    callback(Array.from(target.selectedOptions))
    formik.setFieldValue(field, multiple ? value : value[0])
  }

  return (
    <div className="form-group row">
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>

      <div className="col-11">
        <select className={inputClasses}
                ref={selectRef}
                value={formik.values[field]}
                name={name}
                onChange={onChange}
                multiple={multiple}
                disabled={disabled}>
          {options.map((option) => (
            <option key={`option_${option.key}`} value={option.key}>{option.value}</option>
          ))}
        </select>

        {!!error ? <ErrorMessage message={error} /> : null}
      </div>
    </div>
  )
}

AjaxSelect.propTypes = {
  name: PropTypes.string,
  field: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  error: PropTypes.string,
  ajax: PropTypes.exact({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.number,
      value: PropTypes.string
    })
  ),
  callback: PropTypes.func,
  disabled: PropTypes.bool
}

AjaxSelect.defaultProps = {
  ajax: {},
  icon: {},
  multiple: false,
  options: [],
  callback: () => {},
  disabled: false
}

const isMemoizable = (prev, next) => (
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]
)

export default memo(AjaxSelect, isMemoizable)
