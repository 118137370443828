import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'components'

const IconButton = ({
  type,
  title,
  text,
  iconName,
  className,
  onClick
}) => {
  const btnClasses = classNames('btn', `btn-${type}`, className)
  const iconClasses = classNames({ 'mr-2': !!text })

  return (
    <button className={btnClasses} title={title} type="button" onClick={onClick}>
      <Icon name={iconName} className={iconClasses} />
      {text}
    </button>
  )
}

IconButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

IconButton.defaultProps = {
  type: 'primary',
  iconName: ''
}

export default IconButton
