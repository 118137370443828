import React, { useRef, useContext } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Outlet } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import { AgendaContext } from 'pages/agenda/contexts'
import { LinkToAvailabilities, LinkToExportPdf, LinkToCalendars } from './links'
import { initialView, events, eventDidMount, settings } from 'pages/agenda/utils/calendar'
import { concatCurrentDate } from 'pages/agenda/utils/date'
import { updateAppointment } from 'pages/agenda/actions'
import { Header, SideBar } from '.'

const Calendar = () => {
  let data = useContext(AgendaContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const calendarRef = useRef()

  data = {
    ...data,
    userId: state?.userId || data.currentUserId,
    agendaLabel: state?.agendaLabel || data.currentAgendaLabel
  }

  const { date, calendar } = {
    date: state?.date || moment().format('YYYY-MM-DD'),
    calendar: state?.calendar || 'month'
  }

  const dateClick = ({ date }) => {
    const newDate = concatCurrentDate(date)
    navigate("new_appointment", { state: { ...state, userId: data.userId, newDate } })
  }

  const eventClick = ({ event: { id } }) => {
    navigate(`edit_appointment/${id}`, { state: { ...state, userId: data.userId } })
  }

  const eventUpdate = ({ event: { id, startStr, endStr, extendedProps: { status } } }) => {
    updateAppointment({
      id,
      status,
      userId: data.userId,
      startAt: moment(startStr).toISOString(),
      endAt: moment(endStr).toISOString()
    })
  }

  return (
    <AgendaContext.Provider value={data}>
      <div className="agenda-calendar" key={`${calendar}-${date}`}>
        <Header date={date} />

        <div className='row'>
          <div className='col pr-0 calendar-order'>
            <FullCalendar
              ref={calendarRef}
              initialDate={date}
              initialView={initialView(calendar)}
              events={() => events({ data, state, date })}
              eventDidMount={eventDidMount}
              dateClick={dateClick}
              eventClick={eventClick}
              eventDrop={eventUpdate}
              eventResize={eventUpdate}
              {...settings}
            />
          </div>

          <div className='col-sidebar pl-0'>
            <SideBar date={date} calendar={calendar} />
          </div>
        </div>

        <div className='calendar-mobile-only'>
          <LinkToAvailabilities mobile />

          <LinkToCalendars mobile />

          <LinkToExportPdf date={date} mobile />
        </div>

        <Outlet />
      </div>

    </AgendaContext.Provider>
  )
}

export default Calendar
