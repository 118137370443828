import axios from 'axios'
import { toast } from 'react-toastify'
import {
  headers, formatAppointmentData, formatNewAppointmentData } from './utils'

const getAppointment = async id => {
  return axios.get(`/agenda/appointments/${id}`)
              .then(({ data }) => data)
              .catch(() => toast.error('Une erreur est survenue'))
}

const getAppointments = async (data = {}) => {
  return axios.get('/agenda/appointments', { params: formatAppointmentData(data) } )
              .then(({ data }) => data)
              .catch(() => toast.error('Une erreur est survenue'))
}

const createAppointment = (data = {}) => {
  return new Promise((resolve, reject) => {
    axios.post('/agenda/appointments', formatNewAppointmentData(data), { headers } )
         .then(() => {
            toast.success('Le rendez-vous a bien été créé')
            resolve()
          }).catch(({ response: { data } }) => {
            toast.error('Une erreur est survenue')
            reject(data)
          })
  })
}

const updateAppointment = (data = {}) => {
  return new Promise((resolve, reject) => {
    if (!['preset', 'approved'].includes(data.status))
      return toast.warning('Vous ne pouvez pas modifier les horaires de ce rendez-vous')

    axios.patch(`/agenda/appointments/${data.id}`, formatNewAppointmentData(data), { headers } )
         .then(() => {
           toast.success('Le rendez-vous a bien été mis à jour')
           resolve()
         }).catch(({ response: { data } }) => {
           toast.error('Une erreur est survenue')
           reject(data)
         })
  })
}

const deleteAppointment = async (id) => {
  return axios.delete(`/agenda/appointments/${id}`, { headers })
              .then(() => toast.success('Le rendez-vous a bien été supprimé'))
              .catch(() => toast.error('Une erreur est survenue'))
}

const redoAppointment = async (id) => {
  return axios.patch(`/agenda/appointment_revives/${id}`, {}, { headers })
              .then(() => toast.success('Le rendez-vous a bien été relancé'))
              .catch(error => {
                const message = error.responseJSON?.message
                !!message ? toast.warning(message) : toast.error('Une erreur est survenue')
              })
}

const confirmAppointment = async (id) => {
  return axios.patch(`/agenda/appointment_confirmations/${id}`, {}, { headers })
              .then(() => toast.success('Le rendez-vous a bien été approuvé'))
              .catch(() => toast.error('Une erreur est survenue'))
}

const refuseAppointment = async (id) => {
  return axios.delete(`/agenda/appointment_confirmations/${id}`, { headers })
              .then(() => toast.success('Le rendez-vous a bien été refusé'))
              .catch(() => toast.error('Une erreur est survenue'))
}

const cancelAppointment = async (id) => {
  return axios.patch(`/agenda/appointment_cancels/${id}`, {}, { headers })
              .then(() => toast.success('Le rendez-vous a bien été annulé'))
              .catch(() => toast.error('Une erreur est survenue'))
}

export {
  getAppointment,
  getAppointments,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  redoAppointment,
  confirmAppointment,
  refuseAppointment,
  cancelAppointment
}
