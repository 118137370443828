import React from 'react'
import PropTypes from 'prop-types'
import { AjaxSelect, InputDate, Input, Select, ErrorCard } from 'components'

const AppointmentForm = ({ formik }) => {
  const authenticityToken = $('meta[name=csrf-token]').attr('content')

  const recipientOptions = [formik.values.recipient].map(user => {
    return !!user ? { key: user.id, value: `${user.first_name} ${user.last_name}` } : null
  }).filter(Boolean)

  const serviceOptions = [formik.values.service].map(service => {
    return !!service ? { key: service.id, value: service.name } : null
  }).filter(Boolean)

  const participantOptions = formik.values.participants.map(participant => (
    { key: participant?.id, value: participant?.name }
  )).filter(Boolean)

  const channelOptions = [
    { key: 'Aucune notification', value: 'disabled' },
    { key: 'email', value: 'email' },
    { key: 'SMS', value: 'sms' },
    { key: 'Email & SMS', value: 'both' },
  ]

  const cancellableCallback = (options) => {
    formik.setFieldValue('cancellable', options[0].getAttribute('data-cancellable'))
    formik.setFieldValue('cancellationDelay', options[0].getAttribute('data-cancellation_delay'))
  }

  return (
    <>
      {!!formik.values.errorMessage ? <ErrorCard message={formik.values.errorMessage} /> : null}

      <AjaxSelect name="appointment[service_id]"
                  field="serviceId"
                  formik={formik}
                  options={serviceOptions}
                  error={formik.errors.serviceId}
                  ajax={{ url: '/services.json', title: 'Service' }}
                  icon={{ name: 'exchange-alt', title: 'Service du rendez-vous' }}
                  callback={cancellableCallback} />

      <AjaxSelect name="appointment[recipient_id]"
                  field="recipientId"
                  formik={formik}
                  options={recipientOptions}
                  error={formik.errors.recipientId}
                  ajax={{ url: '/contacts.json', title: 'Destinataire' }}
                  icon={{ name: 'address-book', title: 'Destinataire du rendez-vous' }} />

      <InputDate name="appointment[start_at]"
                  field="startAt"
                  formik={formik}
                  error={formik.errors.startAt}
                  placeholder="Date et heure de début..."
                  icon={{ name: 'calendar', title: 'Date et heure de début du rendez-vous' }} />

      <InputDate name="appointment[end_at]"
                 field="endAt"
                 formik={formik}
                 error={formik.errors.endAt}
                 placeholder="Date et heure de fin..."
                 icon={{ name: 'calendar', title: 'Date et heure de fin du rendez-vous' }} />

      <Input name="appointment[location]"
             field="location"
             formik={formik}
             placeholder="Emplacement"
             icon={{ name: 'map-marked-alt', title: 'Emplacement du rendez-vous' }} />

      <AjaxSelect name="appointment[participant_ids][]"
                  field="participantIds"
                  formik={formik}
                  options={participantOptions}
                  ajax={{ url: '/users', title: 'Participants supplémentaires'  }}
                  icon={{ name: 'users', title: 'Participants supplémentaires (optionnel)' }}
                  multiple />

      <Select name="appointment[channel]"
              field="channel"
              formik={formik}
              options={channelOptions}
              icon={{ name: 'bell', title: 'Canal de notification du destinataire' }} />

      <Input name="appointment[reminder_delay]"
             field="reminderDelay"
             formik={formik}
             type="number"
             formClasse="mb-0"
             min="0"
             max="168"
             icon={{ name: 'user-clock', title: 'Rappel du destinataire (H-x, 0 = désactivé)' }} />

      <input type="hidden" name="user" value={formik.values.userId} />
      <input type="hidden" name="authenticity_token" value={authenticityToken} />
    </>
  )
}

AppointmentForm.propTypes = {
  formik: PropTypes.object.isRequired
}

export default AppointmentForm