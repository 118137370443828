import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'components'

const Input = ({
  name,
  field,
  formik,
  error,
  icon,
  type,
  disabled,
  formClasse,
  ...rest
}) => {
  const onChange = ({ target }) => { formik.setFieldValue(field, target.value) }

  const inputClasses = classNames('form-control', { 'is-invalid': !!error })
  const formClasses = classNames('form-group row', formClasse)

  return (
    <div className={formClasses}>
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>

      <div className="col-11">
        <input className={inputClasses}
               type={type}
               value={formik.values[field] || ''}
               onChange={onChange}
               disabled={disabled}
               {...rest} />
      </div>
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  field: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  error: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  formClasse: PropTypes.string,
  disabled: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  icon: {},
  disabled: false
}

const isMemoizable = (prev, next) => (
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]
)

export default memo(Input, isMemoizable)
