import React, { useEffect, useRef, memo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, ErrorMessage } from 'components'
import { initFlatpickr } from './utils'

const InputDate = ({
  name,
  field,
  formik,
  error,
  icon,
  formClasse,
  disabled,
  ...rest
}) => {
  const inputRef = useRef()
  const formClasses = classNames('form-group row', formClasse)
  const inputClasses = classNames('form-control', { 'is-invalid': !!error })

  const onClose = (_selectedDates, dateStr, _instance) => {
    formik.setFieldValue(field, dateStr)
  }

  useEffect(() => initFlatpickr(inputRef, onClose))

  return (
    <div className={formClasses}>
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>

      <div className="col-11">
        <input className={inputClasses}
               name={name}
               ref={inputRef}
               type="text"
               value={formik.values[field]}
               onChange={() => {}}
               disabled={disabled}
               {...rest} />

        {!!error ? <ErrorMessage message={error} /> : null}
      </div>
    </div>
  )
}

InputDate.propTypes = {
  name: PropTypes.string,
  field: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  error: PropTypes.string,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  disabled: PropTypes.bool
}

InputDate.defaultProps = {
  icon: {},
  disabled: false
}

const isMemoizable = (prev, next) => (
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]
)

export default memo(InputDate, isMemoizable)