import React, { useRef, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, ErrorMessage } from 'components'
import { initSelectpicker } from './utils'

const Select = ({
  name,
  field,
  formik,
  error,
  icon,
  options,
  multiple,
  disabled,
  ...rest
}) => {
  const selectRef = useRef()

  useEffect(() => { initSelectpicker(selectRef.current) }, [])

  useEffect(() => { $(selectRef.current).selectpicker('refresh') })

  const onChange = ({ target }) => {
    const value = Array.from(target.selectedOptions).map(({ value }) => value)
    formik.setFieldValue(field, multiple ? value : value[0])
  }

  const inputClasses = classNames('form-control', { 'is-invalid': !!error })

  return (
    <div className="form-group row">
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>

      <div className="col-11">
        <select name={name}
                className={inputClasses}
                ref={selectRef}
                value={formik.values[field]}
                onChange={onChange}
                disabled={disabled}
                {...rest}>
          {options.map(({ key, value }) => (
            <option key={key} value={value}>{key}</option>
          ))}
        </select>

        {!!error ? <ErrorMessage message={error} /> : null}
      </div>
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string,
  field: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  error: PropTypes.string,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool
}

Select.defaultProps = {
  icon: {},
  multiple: false,
  disabled: false
}

const isMemoizable = (prev, next) => (
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]
)

export default memo(Select, isMemoizable)