import React from 'react'
import PropTypes from 'prop-types'

const Modal = ({ modalRef, children }) => {
  return (
    <div className="modal fade" ref={modalRef}>
      <div className="modal-dialog">
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  modalRef: PropTypes.exact({
    current: PropTypes.instanceOf(HTMLDivElement)
  }).isRequired,
  children: PropTypes.node.isRequired
}

export default Modal