import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import {
  Calendar,
  NewAppointment,
  EditAppointment
} from './components'

const router = createBrowserRouter([{
  path: '/agenda',
  element: <Calendar />,
  children: [
    {
      path: 'new_appointment',
      element: <NewAppointment />
    },
    {
      path: 'edit_appointment/:id',
      element: <EditAppointment />
    }
  ]
}])

export default router
