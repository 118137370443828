import { toast } from 'react-toastify'
// import { flatpickerOpts } from 'components/form/utils'

const openEditAppointmentModal = (event, user, fullcalendar) => {
  const modal = $('#agenda-edit-appointment-modal')

  modal.on('calendarRefresh', () => fullcalendar.refetchEvents())
  modal.on('hide.bs.modal', () => {
    modal.off('hide.bs.modal')
    modal.off('calendar.refresh')
    // setTimeout(() => resetNewAppointmentModal(modal), 300)
  })

  // $('#appointment-start').flatpickr($.extend(flatpickerOpts, { defaultDate: event.startStr,
  //   onChange: () => {
  //     const duration = $(modal.find('#select-service option')[0]).data('duration')
  //     const appointmentStartDate = moment(modal.find('#appointment-start').val(), 'DD/MM/YYYY HH:mm')
  //     const appointmentEndDate = appointmentStartDate.add(duration, 'minutes').format('DD/MM/YYYY HH:mm')
  //     modal.find('#appointment-end').flatpickr($.extend(flatpickerOpts, { defaultDate: appointmentEndDate }))
  //   }
  // }))

  $.ajax(`/agenda/appointments/${event.id}?user_id=${user}`, {
    type: 'GET',
    success: data => {
      const detailUrl = `/appointments/${event.id}?user=${user}`
      let defaultDateObject = null

      modal.find('#select-service').find('option').text(data.service.name)
      modal.find('#select-service').find('option').data('duration', data.service.duration)
      modal.find('#select-service').selectpicker('refresh')

      const recipientName = `${data.recipient.last_name} ${data.recipient.first_name}`
      modal.find('#select-recipient').find('option').text(recipientName)
      modal.find('#select-recipient').selectpicker('refresh')

      defaultDateObject = { defaultDate: moment(data.start_at).format('DD/MM/YYYY HH:mm') }
      modal.find('#appointment-start').flatpickr($.extend(flatpickerOpts, defaultDateObject))
      defaultDateObject = { defaultDate: moment(data.end_at).format('DD/MM/YYYY HH:mm') }
      modal.find('#appointment-end').flatpickr($.extend(flatpickerOpts, defaultDateObject))

      $('#input-location').val(data.location)

      const selectParticipant = modal.find('#select-participants')
      data.participants.forEach(participant => {
        selectParticipant.append(`<option value="${participant.id}" selected>${participant.name}</option>`)
      })
      selectParticipant.trigger('change')

      modal.find(`#appointment-channel option[value="${data.channel}"]`).attr('selected', true)
      modal.find('#appointment-channel').selectpicker('refresh')

      modal.find('#input-reminder-delay').val(data.reminder_delay)

      defaultDateObject = { defaultDate: null }
      if (data.revive_at)
        defaultDateObject.defaultDate = moment(data.revive_at).format('DD/MM/YYYY HH:mm')
      modal.find('#appointment-revive-at').flatpickr($.extend(flatpickerOpts, defaultDateObject))

      modal.data({ eventId: event.id, userId: user })

      modal.find('#edit-appointment-detail').attr('href', detailUrl)

      if (data.status === 'confirmation' && moment().diff(data.start_at) <= 0) {
        modal.find('#edit-appointment-confirm').removeClass('d-none')
        modal.find('#edit-appointment-refuse').removeClass('d-none')
      }

      if (['preset', 'approved'].includes(data.status) && moment().diff(data.start_at) <= 0)
        modal.find('#edit-appointment-cancel').removeClass('d-none')

      if (data.status === 'preset' && moment().diff(data.start_at) <= 0)
        modal.find('#edit-appointment-redo').removeClass('d-none')

      if (['preset', 'approved'].includes(data.status)) {
        modal.find('#appointment-start').attr('disabled', false)
        modal.find('#appointment-end').attr('disabled', false)
        modal.find('#select-participants').attr('disabled', false)
        modal.find('#select-participants').selectpicker('refresh')
        modal.find('#edit-appointment').parent().removeClass('d-none')
        modal.find('#edit-appointment').on('click', () => {
          modal.find('#edit-appointment').text('Patientez...')
          modal.find('#edit-appointment').attr('disabled', true)

          $.ajax(`/agenda/appointments/${event.id}?user_id=${user}`, {
            type: 'PATCH',
            data: {
              appointment: {
                start_at: modal.find('#appointment-start').val(),
                end_at: modal.find('#appointment-end').val(),
                participant_ids: modal.find('#select-participants').val()
              }
            },
            success: () => {
              fullcalendar.refetchEvents()
              // closeModal('agenda-edit-appointment-modal')
              toast.success('Le rendez-vous a bien été modifié')
            },
            error: ({ responseJSON: { message } }) => {
              let custom_message = 'Une erreur est survenue'

              if (responseJSON && message)
                custom_message = message

              modal.find('#edit-appointment-error').removeClass('d-none').text(custom_message)
              modal.find('#edit-appointment').text('Sauvegarder')
              modal.find('#edit-appointment').attr('disabled', false)
              toast.error('Une erreur est survenue')
            }
          })
        })
      }

      modal.modal('show')
    },
    error: () => {
      toast.error('Une erreur est survenue')
    }
  })
}

export { openEditAppointmentModal }