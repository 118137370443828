const headers = { 'x-csrf-token': $('meta[name=csrf-token]').attr('content') }

const setRecipientIds = users => (
  users.flatMap(([_, user]) => user.flatMap(([id, _]) => (id))).join('-')
)

const setServiceIds = services => services.map(([id, _]) => (id)).join('-')

const formatAppointmentData = data => {
  const isRecipient = data.agendaLabel !== "service"
  const recipient_ids = data.recipient_ids?.join('-') || setRecipientIds(data.users)
  const service_ids = data.service_ids?.join('-') || setServiceIds(data.services)

  return {
    start_date: moment(data.date).startOf('month').toISOString(),
    end_date: moment(data.date).endOf('month').toISOString(),
    calendar: data.calendar,
    user_id: data.userId,
    recipient_ids: isRecipient ? recipient_ids : undefined,
    service_ids: isRecipient ? undefined : service_ids
  }
}

const formatNewAppointmentData = data => {
  return {
    user_id: data.userId,
    appointment: {
      service_id: data.serviceId,
      recipient_id: data.recipientId,
      start_at: moment(data.startAt).toISOString(),
      end_at: moment(data.endAt).toISOString(),
      location: data.location,
      participant_ids: data.participantIds,
      channel: data.channel,
      reminder_delay: data.reminderDelay,
      skip_notification: moment(data.startAt).isBefore(),
      cancellable: data.cancellable,
      cancellation_delay: data.cancellationDelay
    }
  }
}

export {
  headers,
  formatAppointmentData,
  formatNewAppointmentData
}
