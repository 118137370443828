import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'

const Header = ({ title, icon, closeModal }) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title">
        { !!icon ? <Icon className="mr-3" {...icon} /> : null }
        {title}
      </h5>

      <button className="close" type="button" onClick={closeModal}>
        <Icon name="times" title="close" />
      </button>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.PropTypes.exact({
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  closeModal: PropTypes.func.isRequired
}

export default Header