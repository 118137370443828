import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const LinkToCalendars = ({ mobile }) => {
  const parentClasses = classNames({ 'pb-2': mobile })
  const linkClasses = classNames('btn btn-sidebar w-100', { 'sidebar-btn': !mobile })

  return (
    <div className={parentClasses}>
      <a className={linkClasses} href="/calendars">
        Gérer les calendriers
      </a>
    </div>
  )
}

LinkToCalendars.propTypes = {
  mobile: PropTypes.bool
}

LinkToCalendars.defaultProps = {
  mobile: false
}

export default LinkToCalendars