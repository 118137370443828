const selectConfig = { liveSearch: true }

const ajaxSelectConfig = (url, customEmptyTitle) => ({
  ajax: {
    url,
    type: 'GET',
    dataType: 'json',
    data: { q: '{{{q}}}' } // eslint-disable-line id-length
  },
  langCode: 'fr-FR',
  locale: {
    emptyTitle: customEmptyTitle
  }
})

const initSelectpicker = node => $(node).selectpicker(selectConfig)

const initAjaxSelectpicker = (node, ajax) => {
  initSelectpicker(node).ajaxSelectPicker(ajaxSelectConfig(ajax.url, ajax.title))
}

const flatpickerOpts = {
  plugins: [new confirmDatePlugin({})], // eslint-disable-line new-cap
  altInput: true,
  altFormat: 'd/m/Y H:i',
  dateFormat: 'Y-m-d H:i',
  enableTime: true,
  time_24hr: true,
  allowInput: true
}

const initFlatpickr = (ref, onClose) => {
  $(ref.current).flatpickr($.extend(flatpickerOpts, { onClose }))
}

export { initSelectpicker, initAjaxSelectpicker, initFlatpickr }
