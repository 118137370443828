import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ className, type, name, title }) => {
  const tooltip = !!title ? { 'data-toggle': 'tooltip' } : {}
  const classNames = `${type} fa-${name} ${className}`

  return <i className={classNames} title={title} {...tooltip} />
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string
}

Icon.defaultProps = {
  className: '',
  type: 'fas'
}

export default Icon
