import axios from 'axios'
import { toast } from 'react-toastify'
import { headers } from './utils'

const updateUser = (userId, user = {}) => {
  return axios.put(`/users/${userId}.json`, { user }, { headers })
              .then(({ data }) => data)
              .catch(() => toast.error('Une erreur est survenue'))
}

export { updateUser }
