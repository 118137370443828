import React from 'react'
import PropTypes from 'prop-types'

const ErrorCard = ({ message }) => {
  return (
    <div className="card border-danger mb-3">
      <div className="card-body text-danger">
        <h5 className="card-title">Une erreur est survenue</h5>

        <p className="card-text">
          {message}
        </p>
      </div>
    </div>
  )
}


ErrorCard.propTypes = {
  message: PropTypes.string
}

export default ErrorCard
