import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { InputDate, Icon, IconButton } from 'components'
import { AgendaContext } from 'pages/agenda/contexts'
import { AppointmentForm } from 'pages/agenda/components'
import {
  redoAppointment,
  confirmAppointment,
  refuseAppointment,
  cancelAppointment
} from 'pages/agenda/actions'

const Form = ({ formik, confirmDelete, closeModal }) => {
  const { currentUserRole } = useContext(AgendaContext)
  const isSameOrBeforeStart = moment().isSameOrBefore(formik.values.startAt)
  const isDeletable = ['admin', 'staff'].includes(currentUserRole)
  const isConfirmable = formik.values.status === 'confirmation' && isSameOrBeforeStart
  const isRefusable = formik.values.status === 'confirmation' && isSameOrBeforeStart
  const isCancelable = ['preset', 'approved'].includes(formik.values.status) && isSameOrBeforeStart
  const isRedoable = formik.values.status === 'preset' && isSameOrBeforeStart


  const onRedoAppointment = () => redoAppointment(formik.values.id).then(() => closeModal())
  const onConfirmAppointment = () => confirmAppointment(formik.values.id).then(() => closeModal())
  const onRefuseAppointment = () => refuseAppointment(formik.values.id).then(() => closeModal())
  const onCancelAppointment = () => cancelAppointment(formik.values.id).then(() => closeModal())

  return (
    <>
      <div className="modal-body">
        <AppointmentForm formik={formik} />

        <InputDate name="appointment[revive_at]"
                  field="reviveAt"
                  formik={formik}
                  error={formik.errors.reviveAt}
                  placeholder="Aucune relance..."
                  formClasse="mt-3 mb-0"
                  icon={{ name: 'redo', title: 'Date de la dernière relance' }} />
      </div>

      <div className="modal-footer">
        <a className="btn btn-primary" title="Détail" href={`/appointments/${formik.values.id}`}>
          <Icon name="eye" />
        </a>

        {isDeletable ? (
          <IconButton type="danger"
                      title="Supprimer"
                      iconName="trash"
                      className="ml-1"
                      onClick={confirmDelete} />
        ) : null}

        {isConfirmable ? (
          <IconButton type="success"
                      title="Confirmer"
                      iconName="check"
                      className="ml-1"
                      text="Confirmer"
                      onClick={onConfirmAppointment} />
        ) : null}

        {isRefusable ? (
          <IconButton type="danger"
                      title="Refuser"
                      iconName="ban"
                      className="ml-1"
                      text="Refuser"
                      onClick={onRefuseAppointment} />
        ) : null}

        {isCancelable ? (
          <IconButton type="danger"
                      title="Annuler"
                      iconName="ban"
                      className="ml-1"
                      onClick={onCancelAppointment} />
        ) : null}

        {isRedoable ? (
          <IconButton type="danger"
                      title="Relancer"
                      iconName="redo"
                      className="ml-1"
                      onClick={onRedoAppointment} />
        ) : null}

        <button className="btn btn-primary ml-1"
                type="button"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}>
          Sauvegarder
        </button>
      </div>
    </>
  )
}

Form.propTypes = {
  formik: PropTypes.object.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default Form
