import React from 'react'
import { useLocation, Link } from 'react-router-dom'

const TypeFilter = () => {
  const { state } = useLocation()
  const { calendar, className, text } = Object.is('list', state?.calendar) ?
    { calendar: 'month', className: 'fa-regular fa-calendar-days', text: ' Calendrier' } :
    { calendar: 'list', className: 'fa-solid fa-list-ul', text: ' Liste' }


  return (
    <Link
      className="btn agenda-controls-btn agenda-calendar-controls-btn "
      state={{ ...state, calendar }}
    >
      <i className={className} />
      {text}
    </Link>
  )
}

export default TypeFilter
