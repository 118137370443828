import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { humanDate } from 'pages/agenda/utils/date'
import { UserFilter, TypeFilter, CalendarFilter } from './filters'

const Header = ({ date }) => {
  const { state } = useLocation()
  const previousMonth = moment(date).subtract(1, 'M').format('YYYY-MM-DD')
  const nextMonth = moment(date).add(1, 'M').format('YYYY-MM-DD')
  const strDate = humanDate(date, state?.calendar)

  return (
    <div className="d-flex flex-column flex-xl-row justify-content-between pb-2 agenda-headertoolbar">
      <div className="agenda-controls">
        <div className='header-calendar-title'>
          <i className="fa-regular fa-calendar-days header-calendar-icon" />
          {' Agenda'}
        </div>

        <Link
          className="btn agenda-controls-btn"
          title="Voir la date d'aujourd'hui"
          state={{ ...state, date }}
        >
          Aujourd'hui
        </Link>

        <div className="controls-btn px-2">
          <Link state={{ ...state, date: previousMonth }}>
            <i className="fa-solid fa-chevron-left pr-3" title="Précédent" />
          </Link>

          <Link state={{ ...state, date: nextMonth }}>
            <i className="fa-solid fa-chevron-right" title="Suivant" />
          </Link>
        </div>

        <div className='header-calendar-date'>{strDate}</div>
      </div>

      <div className="agenda-selectors pr-2">
        <UserFilter />

        <CalendarFilter />

        <TypeFilter />
      </div>
    </div>
  )
}

Header.propTypes = {
  date: PropTypes.string.isRequired
}

export default Header
