import React from 'react'
import PropTypes from 'prop-types'

const CustomInput = ({ onFocus, value, onChange }) => (
  <div className="input-group">
    <div className="input-group-prepend">
      <div className="input-group-text">
        <i className="fa-regular fa-calendar-days" />
      </div>
    </div>
    <input
      className="form-control"
      value={value}
      onFocus={onFocus}
      onChange={onChange}
    />
  </div>
)

CustomInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string
}

CustomInput.defaultProps = {
  onChange: () => null,
  onFocus: () => null,
  value: ''
}

export default CustomInput
