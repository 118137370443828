import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'
import classNames from 'classnames'
import { addEventsIcon } from './icon'
import { getAppointments } from 'pages/agenda/actions'

const calendarStructure = element => {
  const classes = [`event-color-${element.service.color}`, {
    'event-confirmation': element.status === 'confirmation',
    'event-refused': element.status === 'refused',
    'event-canceled': element.status === 'canceled'
  }]

  return {
    id: element.id,
    classNames: classNames(classes),
    status: element.status,
    title: element.agenda_label,
    recipientName: `${element.recipient.last_name} ${element.recipient.first_name}`,
    serviceName: element.service.name,
    serviceColor: element.service_color,
    operator: element.operator,
    location: element.location,
    start: element.start_at,
    end: element.end_at
  }
}

const initialView = calendar => {
  switch (calendar) {
  case 'week':
    return 'timeGridWeek'
  case 'list':
    return 'listMonth'
  case 'day':
    return 'timeGridDay'
  default:
    return 'dayGridMonth'
  }
}

const events = ({ data, state, date }) => (
  getAppointments({ ...data, ...state, date }).then(res => res.map(calendarStructure))
)

const getClosestNum = (target, array) => {
  let [closest] = array
  const targetDate = target || new Date().getDate()
  let minDifference = Math.abs(closest - targetDate)

  if (array[array.length - 1] - targetDate < 0)
    closest = array[array.length - 1]
  else {
    array.forEach((element, index) => {
      if (index === 0)
        return
      const difference = element - targetDate

      if (difference >= 0 && difference < minDifference) {
        closest = element
        minDifference = difference
      }
    })

    if (closest < 10)
      closest = `0${  closest}`
  }

  return closest
}

const scrollToDate = () => {
    let currentSelectedDate = window.location.pathname

    if (currentSelectedDate.includes('date/') && !currentSelectedDate.includes('now'))
      currentSelectedDate = currentSelectedDate.split('date/').pop()
    else
      [currentSelectedDate] = new Date().toISOString().split('T')

    const dates = []

    $.each($('[data-date]'), (index, value) => {
      dates.push(parseInt($(value).data('date').split('-').pop(), 10))
    })

    const [year, month, day] = currentSelectedDate.split('-')
    const closestDay = getClosestNum(parseInt(day, 10), dates)
    const closestDate = `${year}-${month}-${closestDay}`
    const selected_date = $($(`tr[data-date='${  closestDate  }']`)[0])
    const scroller = $($('.fc-scroller')[0])
    const position = selected_date.offset().top - scroller.offset().top + scroller.scrollTop()

    $($('.fc-scroller')[0]).scrollTop(position)
  }

const eventDidMount = info => {
  if (info.view.type === 'listMonth') {
    $('.fc-list-table th[scope="colgroup"]').toArray().forEach(element => {
      const parentHeader = $(element).parent()
      const weekDay = $($(element).find('.fc-list-day-side-text')).text()

      if (weekDay)
        $(element).find('.fc-list-day-text').prepend(`${weekDay[0].toUpperCase() + weekDay.substring(1)} `)

      $($(element).find('.fc-list-day-side-text')).remove()
      $(element).attr('colspan', 6)

      if (!$(parentHeader.next()).hasClass('custom-list-header')) {
        parentHeader.after(`
          <tr class="custom-list-header">
            <th class="custom-list-month-column"><div>Créneau</div></th>
            <th class="custom-list-month-column" colspan="2"><div>Service</div></th>
            <th class="custom-list-month-column"><div>Destinataire</div></th>
            <th class="custom-list-month-column"><div>Opérateur</div></th>
            <th class="custom-list-month-column"><div>Emplacement</div></th>
          </tr>`)
      }
    })

    const recipientColumn = document.createElement('td')
    const locationColumn = document.createElement('td')
    const operatorColumn = document.createElement('td')

    recipientColumn.textContent = info.event.extendedProps.recipientName
    operatorColumn.textContent = info.event.extendedProps.operator || '...'
    locationColumn.textContent = info.event.extendedProps.location || '...'

    $($(info.el).find('.fc-list-event-title')[0]).text(info.event.extendedProps.serviceName)
    $($(info.el).find('.fc-list-event-dot')).css('color', info.event.extendedProps.serviceColor)
    $($(info.el).find('.fc-list-event-dot')).text('●')
    $($(info.el).find('.fc-list-event-dot')).removeClass('fc-list-event-dot')

    info.el.append(recipientColumn)
    info.el.append(operatorColumn)
    info.el.append(locationColumn)

    if ($(info.el).next().hasClass('fc-list-day')) {
      $(info.el).after(`
        <tr class="custom-list-spacing-row">
        </tr>`
      )
    }

    scrollToDate()
    $('.fc-scroller').css('overflow', 'auto')
  }

  // if (info.event.id === appointmentOpen)
  //   openEditAppointmentModal(info.event, userId, info.view.calendar)

  addEventsIcon()
}

const settings = {
  locales: [frLocale],
  dayMaxEvents: true,
  dayMaxEventRows: true,
  nowIndicator: true,
  editable: true,
  eventMaxStack: 4,
  headerToolbar: false,
  themeSystem: 'bootstrap',
  locale: 'fr',
  timeZone: 'Europe/Paris',
  plugins: [
    bootstrapPlugin,
    dayGridPlugin,
    interactionPlugin,
    listPlugin,
    timeGridPlugin,
    momentTimezonePlugin
  ],
  views: {
    timeGrid: {
      allDaySlot: false,
      slotDuration: '00:15:00',
      scrollTime: moment().format('H:00'),
      dayMaxEventRows: 4
    }
  }
}

export { initialView, events, eventDidMount, settings }
