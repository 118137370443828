import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { Modal, ModalHeader } from 'components'
import { Form } from './editBody'
import { strDate } from 'pages/agenda/utils/date'
import { setLocale, object, string } from 'yup'
import { getAppointment } from 'pages/agenda/actions'
import { fr } from 'locales/yup'
import { ConfirmDelete, ConfirmUpdate } from './editBody'

const EditAppointment = () => {
  const navigate = useNavigate()
  const modalRef = useRef()
  const [validateOnChange, setValidateOnChange] = useState(false)
  const [editBodyMode, setEditBodyMode] = useState('form')
  const { state } = useLocation()
  const { id } = useParams()

  const initialValues = {
    id,
    userId: '',
    serviceId: '',
    service: {},
    recipient: {},
    recipientId: '',
    startAt: strDate(),
    endAt: strDate(),
    participants: [],
    location: '',
    participantIds: [],
    channel: 'disabled',
    reminderDelay: 0,
    cancellable: false,
    cancellationDelay: 0,
    reviveAt: '',
    status: '',
    errorMessage: ''
  }

  const validationSchema = object({
    recipientId: string().label('Le destinataire').required(),
    serviceId: string().label('Le service').required(),
    startAt: string().required(),
    endAt: string().label('La date de fin').required().test(
      (date, { parent: { startAt } }) => moment(startAt).isBefore(date)
    )
  })

  const closeModal = () => {
    $(modalRef.current).modal('hide')
    navigate('/agenda', { state })
  }

  const onSubmit = () => setEditBodyMode('confirmUpdate')
  const returnToForm = () => setEditBodyMode('form')
  const confirmDelete = () => setEditBodyMode('confirmDelete')

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange,
    onSubmit
  })

  useEffect(() => { !formik.isValid ? setValidateOnChange(true) : null}, [formik.isValid])

  useEffect(() => {
    setLocale(fr)
    $(modalRef.current).on('hidden.bs.modal',() => closeModal())

    getAppointment(id).then(data => {
      formik.setFieldValue('serviceId', data.service.id)
      formik.setFieldValue('service', data.service)
      formik.setFieldValue('recipient', data.recipient)
      formik.setFieldValue('recipientId', data.recipient.id)
      formik.setFieldValue('recipient', data.recipient)
      formik.setFieldValue('startAt',  strDate(data.start_at))
      formik.setFieldValue('endAt', strDate(data.end_at))
      formik.setFieldValue('location', data.location)
      formik.setFieldValue('participants', data.participants)
      formik.setFieldValue('participantIds', data.participants.map(({ id }) => id))
      formik.setFieldValue('channel', data.channel)
      formik.setFieldValue('reminderDelay', data.reminder_delay)
      formik.setFieldValue('reviveAt', data.revive_at || '')
      formik.setFieldValue('status', data.status)

      $(modalRef.current).modal('show')
    })
  }, [])

  const renderEditBody = () => {
    switch(editBodyMode) {
      case 'confirmDelete':
        return <ConfirmDelete id={id} returnToForm={returnToForm} closeModal={closeModal} />
      case 'confirmUpdate':
        return <ConfirmUpdate formik={formik} returnToForm={returnToForm} closeModal={closeModal} />
      default:
        return <Form formik={formik} confirmDelete={confirmDelete} closeModal={closeModal} />
    }
  }

  return (
    <Modal modalRef={modalRef}>
      <ModalHeader title="Informations sur le rendez-vous"
                   icon={{ name: 'calendar-alt', title: 'Informations sur le rendez-vous' }}
                   closeModal={closeModal} />

      {renderEditBody()}
    </Modal>
  )
}

export default EditAppointment
