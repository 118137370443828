import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { AgendaContext } from 'pages/agenda/contexts'
import { updateUser } from 'pages/agenda/actions'

const AgendaFilter = ({ calendar }) => {
  const { userId, agendaLabel } = useContext(AgendaContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const checked = (state?.agendaLabel || agendaLabel) !== 'service'

  const onChange = ({ target }) => {
    const agendaLabel = target.checked ? 'recipient' : 'service'
    updateUser(userId, { agenda_label: agendaLabel }).then(
      navigate(null, { state: { ...state, agendaLabel } })
    )
  }

  return (
    <>
      {Object.is('list', calendar) ? '' : (
        <div className='sidebar-agenda-switch pb-2'>
          <label className="custom-control agenda-custom-switch">
            <span className="agenda-custom-switch-control-description">Service</span>
            <input
              type="checkbox"
              className="agenda-custom-switch-control-input"
              id="agenda-label-switch"
              checked={checked}
              onChange={onChange}
            />
            <span className="agenda-custom-switch-control-indicator" />
            <span className="agenda-custom-switch-control-description">Destinataire</span>
          </label>
        </div>
      )}
    </>
  )
}

AgendaFilter.propTypes = {
  calendar: PropTypes.string.isRequired
}

AgendaFilter.defaultProps = {
  calendar: 'month'
}

export default AgendaFilter